.conversation-list-item {
  display: flex;
  align-items: center;
  padding: 10px;  
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}
.conversation-list-item-member{
  display: flex;
  align-items: center;
  padding: 10px;  
  border-radius: 5px;
  margin-right: 70px;
  cursor: pointer;
}
.conversation-list-item-member:hover{
 background-color: grey;
 opacity:0.5;
}
.conversation-list-item-current {
  background:#f9fafc;
  border: solid 1px #dbe5ed;;
  color: #747576;
}

.conversation-action {
  position: absolute;
  right: 10px;
  color:#447df7;
  font-size:16px;
}

.conversation-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  font-size:46px;
}

.conversation-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0 10px;
  padding-top: 5px;
  color: #4b5155;
}
.conversation-room-initials{
  text-transform: uppercase;
  font-weight: 900;
  font-size: 18px;
  color: #4b5155;
  margin: 0 5px;
  width: 50px;
  height: 50px;
  background-color: #dfdfdf;
  border-radius: 50%;  
  align-items: center;
  display: flex;
  justify-content: center;
}
.conversation-snippet {
  font-size: 12px;
  color: #7b8793;
  font-style: italic;
  margin-left: 10px;
}

.conversation-last-message-time{  
  color: #7b8793;    
  margin-left: auto;
}

.conversation-badge {
  margin-left: auto;
  display: flex;
  justify-content: center;
  background: #4c83f7;
  color:white;
  font-weight: bold;
  border-radius: 50%;
  line-height:14px;
  width:25px;
  height: 25px;
  text-align: center;
  padding:5px;
}
