.compose {
  padding: 0 5px;
  display: flex;
  align-items: center;
  background: white;  
  position: fixed;
  width: 100%;
  bottom: 16px;  
  border: 1px solid #e4e5e5;
  border-radius: 5px;
  margin-bottom: 10px;
}

.compose-button{
  border: none;
  background: transparent;
  padding: 3px;
}

@supports (backdrop-filter: blur(20px)) {
  .compose {        
    backdrop-filter: blur(20px);
  }
}

.compose-input {
  flex: 1;  
  font-size: 14px;
  height: 40px;
  font-family: Roboto;
  font-weight: 600;
  background: none;
  padding-right: 10px;
  border: none;
  border-radius: 10px;
}

.compose-upload-image{
  width: 40px;
  height: 40px;
  margin: 3px;
  border-radius: 50%;  
}

.compose-input::placeholder {
  opacity: 0.3;
}

.compose .toolbar-button {
  color: #bbbbbf;
  margin-left: 15px;
}

.compose .toolbar-button:hover {
  color: #99999c;
}
