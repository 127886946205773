.messenger {
  display: grid;
  width: 100%;
  height: calc(100vh - 50px);
  background: #eeeef1;

  grid-template-columns: 350px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.messenger .container {
  padding: 10px;
}

.messenger .scrollable {
  position: relative;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #e4e5e5 #f9fafc;
  -webkit-overflow-scrolling: touch;
}

.messenger .chatsidebar {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.messenger .content {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.messenger .footer {
  grid-column-start: 2;
  background: white;
}

/*for chrome,safari,opera,edge*/
/* width */
.scrollable::-webkit-scrollbar {
  width: 6px;
  margin: 0 15px;
}

/* Track */
.scrollable::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dbe5ed; 
  border-radius: 10px;  
}
 
/* Handle */
.scrollable::-webkit-scrollbar-thumb {
  background-color: #e4e5e5; 
  border-radius: 10px;
}