@media screen and (max-width: 1024px){
  // Gradient scroll
  .horizontal-scroll_wrap{
    width: calc(100% + 20px);
    overflow: hidden;
    position: relative;
    left: -10px;
    &:after, &:before{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 10px;
      z-index: 2000;
    }
    &:after{
      right: 0;
      background-image: linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0))
    }
    &:before{
      left: 0;
      background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0))
    }
    &[data-color='blue']{
      &:after{
        background-image: linear-gradient(to left, #F4F8F9, rgba(255,255,255,0))
      }
      &:before{
        background-image: linear-gradient(to right, #F4F8F9, rgba(255,255,255,0))
      }
    }
  }
  .horizontal-scroll{
    width: 100%;
    overflow-x: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    -webkit-overflow-scrolling: touch;
    &:after, &:before{
      content: '';
      display: inline-block;
      min-width: 10px;
      max-width: 10px;
      height: 1px;
      background-color: transparent;
    }
  }
  .main-panel{
    .main-content {
      padding: 15px 0;
    }
  }
  // Maps
  .project-map{
    height: 300px;
  }

  // Tables
  .table-dashboard-schedule{
    min-width: 500px;
    overflow-y:scroll;
   height:100px;
   display:block;
   border: 2px solid red;
  }
  .table-project-small{
    min-width: 500px;
  }
  

  // Schedules
  .scheduler{
    .open .dropdown-menu{
      position: absolute;
      background-color: #fff;
      border: 1px solid #ccc;
      box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
    }
  }
}
@media screen and (max-width: 600px){
  // Schedules
  .scheduler {
    & > thead {
      td {
        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;

          & > div {
            margin-bottom: 15px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
