.signature-initial{
    background-color: #fff;        
}

.scrollX{
    overflow-x: scroll;
    border: grey 2px solid;
    border-radius: 10px;
    background-color: #fff;    
}


.wrapper-row{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
}
.fontpickerSignature #font-picker{
    width:100% !important;
    background-color: #ffffff !important;
    border: 1px solid #cccccc !important;
    border-radius: 4px !important;
    color: #565656 !important;
    padding: 6px 12px !important;
    height: 40px !important;
    box-shadow : none !important;
    cursor: auto !important;
}
.fontpickerSignature #font-picker:hover{
    border:1px solid rgb(68, 125, 247) !important;
    cursor: auto !important;
}
.fontpickerSignature #font-picker:focus{
    cursor: auto !important;
}
 .fontpickerSignature .dropdown-button{
    background-color: white !important;
    margin-top: -5px;
}
.fontpickerSignature #font-picker .dropdown-button:focus{
    cursor: auto !important;
}
.fontpickerSignature #font-picker .font-list{
    background-color: white !important;
    margin-left: -12px !important;
    margin-top: 5px;
    
}
.fontpickerSignature #font-picker .dropdown-icon.finished::before{
    border-top: 6px solid #cccccc !important;
}
.fontpickerSignature #font-picker .expanded .dropdown-icon.finished::before{
    border-top: 6px solid black !important;
}
.fontpickerSignature #font-picker ul li button:hover{
    background-color:#cce0ff  !important;
    cursor: auto !important;
}
.fontpickerSignature #font-picker .active-font{
    background-color: rgb(68, 125, 247);
    color:white;
}