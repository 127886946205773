.sendContainer {

}

.sendContainer .title{

}

.recipientCont {
    margin-top: 2em;
}
.recipientCont .mailTag {
    border-radius: 20px;
    background-color: #f9fafc;
    padding: 0.2em 1.2em;
    margin: 0.5em .5em 0 0;
}
.recipientCont .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.sendContainer .inputLable{
    line-height: 1.33;
    display: block;
    font-weight: 100;
}
.sendContainer .charCount{
    display: block;
    opacity: 0.5;
    line-height: 1.43;
}
.sendContainer .react-datepicker__input-container::before{
    content: url(../../../assets/img/calendar.svg);
    display: block;
    position: absolute;
    top: 5px;
    left: unset;
    right: 1em;
    margin: auto 0;
    bottom: 0;
    pointer-events: none;
}

.sendContainer .flexCont{
    display: flex;
    /* width: 80%; */
    justify-content: space-between;
}
.sendContainer .expDate{
    flex-grow: .5;
    margin-right: 1em;
}
.sendContainer .btn{
    margin: 2em 1em 0 0;
}
.sendContainer input,
.sendContainer textarea{
    border: solid 1px #dbdbdb;
    width: 80%;
}
.sendContainer input{
    padding: .7em;
    border-radius: 5px;
}

.sendContainer input::placeholder,
.sendContainer textarea::placeholder{
    opacity: 0.5;
}
.sendContainer textarea {
    height: 130px;
    resize: none;
    padding: 0.3em;
    border-radius: 5px;
}
.sendContainer textarea:focus {
    outline: 0 !important;
}
.sendContainer .reviewField {
    margin-top: 2em;
}
.sendContainer .react-datepicker__input-container input{
    padding-left: 1em !important;
}
.successPage{
    display: block;
    text-align: center;
    padding-top: 120px;
}
.successPage h1{
    font-weight: bolder;
    font-size: 40px;
}
.successPage p{
    color: grey;
    margin-bottom: 20px;
}
.btnSignNow {
    background: #3370f4 !important;
    color: white !important;
    font-weight: bold !important;
}

.actions-center .compose-btn{
    /* background-color: transparent; */
    margin-top: -2.5px;   
    /* margin-right: 10px; */
    margin-left: -2px;
    
}

.img1{
    /* size: small; */
    width: 25px;
    height: 25px;
    margin-left: 1.3px;
    margin-right: 1.3px;
    cursor: pointer;
}