@media screen and (min-width: 1050px){
  .bulk-shift-modal {
    /* width: 1000px !important; */
    width: 800px !important;
  }
}


/* New shift-form css */
 .waiting_badge{ 
  background-color: #ffce6b;
  margin:0 0 0 14px;
  padding:7px 0px 6px;
  border-radius: 50px;
  font-size: 14px;
}

.fs15{
  font-size: 15px;
  line-height: 18px;
}

.react-datepicker__input-container input{
  padding:10px 20px 10px 47px !important;
  

}

/* Css for new calendar of month */

.customize_calendar{
  
}

/* .customize_calendar .rbc-toolbar{
  display: none;
} */

.customize_calendar .rbc-event{
  /* background-color: #f9f9f9; */
  margin: 0 0px;
  width: inherit;
  background-color: #fff;
  border-radius: none;
}

.customize_calendar .rbc-event .rbc-event-content{
  font-size: 11px;
  /* font-family: Roboto; */
  background-color: #fff;
  color: #000;
  
}

.customize_calendar .vacation_absent_event{
  background-color: #447df7;
  color: #fff;
  padding-left: 2px;
}

/* .customize_calendar .selected_event{
  background-color: #447df7 !important;
} */
.customize_calendar .selected_event .rbc-event-content .event_content .none_Event{
  background-color: #447df7 !important;
  color: #fff !important;
  
}
.customize_calendar .selected_event .rbc-event-content .event_content .none_Event .none_circle{
  color: #fff !important;
 
}
.customize_calendar .selected_event .rbc-event-content .event_content .noshow_Event{
  background-color: #b4b4b4 !important;
  color: #fff !important;
  
}
.customize_calendar .selected_event .rbc-event-content .event_content .noshow_Event .noshow_circle{
  color: #fff !important;
}
.customize_calendar .selected_event .rbc-event-content .event_content .ontime_Event{
  background-color: #73B539 !important;
  color: #fff !important;
 
}
.customize_calendar .selected_event .rbc-event-content .event_content .ontime_Event .ontime_circle{
  color: #fff !important;
 
}
.customize_calendar .selected_event .rbc-event-content .event_content .late_Event{
  background-color: red !important;
  color: #fff !important;
}
.customize_calendar .selected_event .rbc-event-content .event_content .late_Event .late_circle{
  color: #fff !important;
}


.customize_calendar .rbc-month-view .rbc-month-row .rbc-row-bg .rbc-today{
  /* background: #eaf6ff; */
  /* background: #447df7; */
  border:1px solid #447df7;
  background-color: #fff;
}

.customize_calendar .rbc-month-view .rbc-header{
  font-size: 15px;
  color: #333;
  font-weight: 600;
  text-align: center;
  font-family: Roboto;
  letter-spacing: 0.8px;
}

.customize_calendar .rbc-event:focus{
  outline: none;
}

.customize_calendar .rbc-event{
  margin-top: 2px;
}

/* Overlay styling */
.rbc-overlay .rbc-overlay-header{
  font-size: 15px;
  color: #333;
  font-family: Roboto;
}

.rbc-overlay .rbc-event{
  background-color: #f9f9f9;
  padding: 3px;
  margin-top: 2px;
  font-size: 11px;
  color: #fff;
  /* font-family: Roboto; */
}

.rbc-event:focus{
  outline: none !important;
}

.rbc-overlay .selected_event .rbc-event-content .event_content .none_Event{
  background-color: #447df7 !important;
  color: #fff !important;
 
}
.rbc-overlay .selected_event .rbc-event-content .event_content .none_Event .none_circle{
  color: #fff !important;
 
}
.rbc-overlay .selected_event .rbc-event-content .event_content .noshow_Event{
  background-color: #b4b4b4 !important;
  color: #fff !important;
  
}
.rbc-overlay .selected_event .rbc-event-content .event_content .noshow_Event .noshow_circle{
  color: #fff !important;
 
}
.rbc-overlay .selected_event .rbc-event-content .event_content .ontime_Event{
  background-color: #73B539 !important;
  color: #fff !important;
 
}
.rbc-overlay .selected_event .rbc-event-content .event_content .ontime_Event .ontime_circle{
  color: #fff !important;
 
}
.rbc-overlay .selected_event .rbc-event-content .event_content .late_Event{
  background-color: red !important;
  color: #fff !important;
}
.rbc-overlay .selected_event .rbc-event-content .event_content .late_Event .late_circle{
  color: #fff !important;
}

.rbc-overlay .vacation_absent_event{
  background-color: #447df7;
  color: #fff;
}


.customize_calendar .rbc-row .rbc-event-content{
  /* padding: 3px; */
  /* border-radius: 5px; */
}

/* .customize_calendar .rbc-calendar .rbc-toolbar span:nth-last-child(1){
  display: none;
} */

/* Customize toolbar */

.customize_toolbar .rbc-toolbar{
  padding:0px;
  margin-bottom: 0px;
}

.customize_toolbar .rbc-toolbar .rbc-btn-group{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  margin-bottom: 1em;
}

.customize_toolbar .rbc-toolbar .rbc-btn-group:nth-of-type(1){
  flex-grow: 1;
}

.customize_toolbar .rbc-toolbar .rbc-btn-group:nth-of-type(2){
  flex-grow: 3;
}

.customize_toolbar .rbc-toolbar .rbc-btn-group:nth-of-type(3){
  flex-grow: 1;
}

.customize_toolbar .rbc-toolbar .month_btn{
  border: 1px solid #447df7;
  color: #447df7;
}

.customize_toolbar .rbc-toolbar .month_btn:hover{
  border: 1px solid #447df7;
  background-color: white;
  /* color: #9fb3dd !important; */
  color: #447df7 !important;
}

.customize_toolbar .rbc-toolbar button{
  border: none;
}

.customize_toolbar .rbc-toolbar .rbc-toolbar-label{
  font-size: 15px;
}

.customize_toolbar .rbc-toolbar .rbc-btn-group button:hover{
  background-color: #fff;
  color: #b4b4b4;
}
.customize_toolbar .info_box{
  position: absolute;
  width: 150px;
  min-height: 100px;
  background-color: #fff;
  color: '#000';
  border: 1px solid #ddd;
  z-index: 10;
  /* left: -42px; */
  right: -25px;
  top: 37px;
 border-radius: 10px;
 padding: 9px;
 font-size: 14px;
 white-space: normal;
 font-family: Roboto;
 box-shadow: 0 0 6px #ddd;
}

.customize_toolbar .hide_info_box{
  display: none;
}

/* Customize toolbar end */

/* End css for new calendar of month */


/* End shift-form css */

/* Availability module css */
.availability-card{
  margin-top: 1em;
  border: 1px solid lightgray;
  border-radius: 4px;
}
.availability-days{
  border-bottom: 1px solid lightgray;
  display: flex;
  align-items: center;
  padding: 1em;
}
.availability-days .title{
  color: #000;
}
.availability-days::before{
  font-size: 2em;
  content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
}
.availability-table .rt-thead .rt-resizable-header-content, .availability-table .rt-thead .rt-tr{
  background-color: transparent !important;
  color: gray !important;
}

.availablility-status{
  margin : 10px 0px 10px 0px;
  padding : 0px 10px 0px 10px;
  border-radius : 20px
}

.green{
  border : 2px solid lightgreen
}

.orange{
  border : 2px solid rgb(255 206 107)
}

.red{
  border : 2px solid red
}

.availability-status-title{
  margin : 10px 0px 10px 0px
}