.message {
  display: flex;
  flex-direction: column;
}

.message .timestamp {
  display: flex;
  justify-content: center;
  color: #8a8a8a;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-size: 11px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.message .bubble-container {
  display: flex;  
  /* margin: 5px 0; */
}

.message.mine .bubble-container {
  justify-content: flex-end;
}

.message.start .bubble-container .bubble {
  margin-top: 10px;
  /* border-top-left-radius: 20px; */  
  border-top-left-radius: 10px;  
}

.message.end .bubble-container .bubble {
  /* border-bottom-left-radius: 20px; */  
  border-bottom-left-radius: 10px;  
  margin-bottom: 10px;
}

.message.mine.start .bubble-container .bubble {
  margin-top: 10px;
  /* border-top-right-radius: 20px; */
  border-top-right-radius: 10px;  
}

.message.mine.end .bubble-container .bubble {
  /* border-bottom-right-radius: 20px; */  
  border-bottom-right-radius: 10px;  
  margin-bottom: 10px;
}

.message .bubble-container .bubble {
  margin: 1px 0px;  
  padding: 10px 15px;
  border-radius: 10px;
  max-width: 65%;
  background: #f6f7f7;
  word-wrap: break-word;
  font-size: 14px;  
  color: #333333;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;  
  font-family: Roboto;
  line-height: 1.57;
  letter-spacing: normal;  
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.message.mine .bubble-container .bubble {
  background:#f6f7f7;
  /* color: white;*/
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.message .bubble-container .avatar-container {
  width:66px;
  height: 40px;
  display: flex;
}

.avatar-wrapper .user-title {
  white-space: nowrap;
  width: 66px;
  font-size: 12px;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  line-height: 12px;
  font-family: Roboto;
  font-weight: 600;
  display: block;
  z-index: 100;
  cursor: default;
  margin-top: -9px;
}

.avatart-profile{  
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
  transition: all 0.3s ease-in-out;
  cursor: default;  
}

.message-image{
  max-height:400px;
  max-width:400px;
  border-radius:10px;
}