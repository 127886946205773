// variables and mixins
@import "./lbd/variables";
@import "./lbd/mixins";
@import "fonts";
@import './scheduling.scss';
@import './notifications-dock.scss';
@import './map.scss';
@import './tables.scss';
@import './charts.scss';
@import './custom-form.scss';
@import './theme-customization.scss';
@import './wrappers.scss';
@import './stamped.scss';
@import './profile.scss';
@import './react-calendar.scss';
@import './jsonFormWidgets.scss';

body{
  font-family: "Open Sans", "Roboto", "Helvetica Neue", sans-serif;
  overscroll-behavior-x: none;
}
.landing_nav{
  height: 100vh;
  width: 100vw;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  a{
    margin: 0 10px;
    font-size: 20px;
    &:hover{
      text-decoration: underline;
    }
  }
}
.list-errors{
  padding: 14px;
  border-radius: 4px;
  border: 2px solid #F2105A;
  display: flex;
  flex-direction: column;
  color: #F2105A;
  margin: 10px auto;
  width: 100%;
  &__item{
    &:first-letter{
      text-transform: uppercase;
    }
    color: #F2105A;
  }
}
/*
.ReactTable{
  border: none;
  *{
    border: none!important;
  }
  .rt-thead{
    &.-header{
      box-shadow: none;
    }
    .rt-tr{
      background-color: $default-color-light;
    }
    .rt-resizable-header-content{
      color: $default-color;
      font-weight: 900;
      text-align: center;
      font-size: 14px;
    }
  }
  .rt-tbody{
    overflow: hidden;
    .rt-tr-group{
      margin-top: 5px;
      max-height: 40px;
    }
    .rt-tr{
      max-height: 40px;
      &.-even{
        background-color: $default-color-light;
        &:hover{
          background-color: $default-color-light!important;;
        }
      }
      &.-odd{
        background-color: #fff;
        &:hover{
          background-color: #fff!important;
        }
      }
    }
    .rt-td{
      text-align: center;
      color: $light-gray;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .pagination-bottom{
    .-pagination{
      box-shadow: none;
    }
  }
}
*/
.ReactTable{
  border: none;
  .rt-thead {
    &.-header {
      box-shadow: none;
    }
  }
  .rt-tbody {
    overflow: hidden;

    .rt-tr-group {
    }

    .rt-tr {
      max-height: 40px;
    }

    .rt-td {
    }
  }
}


.Table-pag{
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  &__prevPageWrapper{

  }
  &__nextPageWrapper{

  }
  &__page-nav{
    padding: 0;
    display: flex;
    align-items: center;
    background: transparent;
    outline: none!important;
    border: none!important;
    i{
      font-size: 30px;
      font-weight: 900;
      color: $default-color;
      transition: 0.3s ease-in-out all;
      &:hover{
        text-shadow: 1px 1px 10px $default-color;
      }
    }
    &_disabled{
      opacity: 0.5;
      i{
        &:hover{
          text-shadow: none;
        }
      }
    }
  }
  &__pageButton{
    min-height: 28px;
    min-width: 28px;
    border-radius: 15px;
    outline: none!important;
    border: none!important;
    font-size: 14px;
    font-weight: 600;
    color: $default-color;
    transition: .3s ease-in-out all;
    &:hover{
      background-color: $default-color-light;
    }
    &_active{
      background-color: $default-color!important;
      color: #ffffff!important;
    }
  }
  &__visiblePagesWrapper{
    display: inline-flex;
    margin-right: 10px;
    margin-left: 10px;
    padding: 0;
    border-radius: 5px;
    button{
      padding: 0;
      margin-right: 5px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
.actions-center {
  padding-top:0px;
  margin-top:-2px;
  text-align: right !important;
  display: flex;
  .btn-simple {
    padding: 3px 4px 3px 4px;
    font-size: 18px;
    background: #447df7;
    border-radius: 5px;
    color: white;
    margin-left:5px;
    &:hover {
      background: #2769f6;
      color: white;
    }
  }
  .no-na-btn{
    margin: 0px 5px;
  }
  .btn-green{
    padding: 3px 4px 3px 4px;
    background: green;
    border-radius: 5px;
    color: white;
    margin-left:5px;
    border: none !important;
    &:hover {
      background: green;
      color: white;
    }
  }
}
.actions-center-signdoc {
  padding-top:0px;
  margin-top:-2px;
  text-align: right !important;
  display: flex;
  justify-content: center;
  .btn-simple {
    padding: 3px 4px 3px 4px;
    font-size: 18px;
    background: #447df7;
    border-radius: 5px;
    color: white;
    margin-left:5px;
    &:hover {
      background: #2769f6;
      color: white;
    }
  }
}
.list-main-column {
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}
.status-box{
  border-radius: 10px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  padding: 2px 10px;
  min-width: 70px;
  &_active{
    background-color: $green-color-top;
    color: $green-color-bottom;
  }
  &_inactive{
    background-color: $red-color-top;
    color: $red-color-bottom;
  }
}
.header-meta{
  margin-bottom: 20px;
  button{
    font-weight: 600;
    margin-right: 10px;
    &:last-child{
      margin-right: 0;
    }
  }
}
.radio-widget{
  display: flex;
  flex-wrap: wrap;
  min-height: 40px;
  align-items: center;
  .radio{
    margin: 5px;
  }
  &.vertical {
    display: block;
  }
}
.form-chooser{
  .row{
    .array-item{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      & > div{
        max-width: calc(100% - 58px);
        width: 100%;
      }
      &-toolbox{
        min-width: 38px!important;
        max-width: 38px!important;;
        padding: 0;
      }
      .array-item-remove {
        max-width: 50px;
      }
    }
    .array-item-add{
      width: 20%;
      margin-left: 40%;
    }
  }
}
.array-item-remove {
  max-width: 50px;
}

.list-filters{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  /*background: #E0DBFB;*/
  border-radius: 5px;
  border: 1px solid #d0d0d0;
  margin: 15px 0 27px 0;
  button{
    margin-right: 10px;
    &:last-child{
      margin-right: 0;
    }
  }
  //.form-control{
  //  width: auto;
  //  margin: 0 15px;
  //  flex-grow: 1;
  //  border-radius: 5px;
  //}
  &__item{
    display: flex;
    align-items: center;
    padding: 10px;
    &_to-right{
      margin-left: auto;
    }
    &_grow{
      flex-grow: 1;
    }
    .input-block{
      padding-top: 0;
      width: 100%!important;
    }
  }
  .managers-widget{
    width: 150px;
  }
}
.table-list{
  &__summary{
    display: flex;
    width: 100%;
    align-items: center;
    div {
      margin-right: 20px;
      font-size: 18px;
    }
    div.highlighted {
      color: #447df7;
    }
    div.highlightedred {
      color:#E73C6E;
    }
    div.highlightedgreen {
      color: #4fbf58;
    }
  }

  &__header{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    button{
      margin: 5px 10px;
    }
  }
}
.primary-page{
  padding: 0;
  box-sizing: border-box;
  &__header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  &__title{
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.4px;
    color: #34525C;
    &__draft{
      color: $red-color-bottom;
      font-size:12px;
    }
  }
}
.project-statuses{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 40px;
  .radio{
    padding: 5px;
    margin: 0;
  }
}

.checkbox label, .radio label{
  user-select: none;
}

.entity-attachment .btn-doc-upload{
  margin-top: -4.3em !important;
  margin-left: 50.5em !important;
}

.uploads{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  width: 100%;
  user-select: none;
  flex-wrap: wrap-reverse;
  &-item{
    display: block;
    padding: 10px;
    text-align: center;
    width: 100px;
    margin: 0;
    &__title{
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #447df7;
    }
    &__img{
      max-width: 60px;
      width: 100%;
      cursor: pointer;
    }
    &_single{
      width: 100%;
      max-width: 100%;
      margin: auto;
    }
    &__file{
      position: relative;
      display: inline-block;
    }
    &__delete{
      position: absolute;
      top: 0;
      right: 0;
      font-size: 18px;
      font-weight: 900;
      outline: none !important;
      border: none;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      color: rgba(255, 30, 80, 0);
      background-color: transparent;
      &:hover{
        color: rgba(255, 30, 80, 0.5);
      }
    }
    &__icon{
      cursor: pointer;
      font-size: 60px;
      color: #447df7;
    }
    &:hover{
      .uploads-item__delete{
        color: rgba(255, 30, 80, 1);
        background-color: #ffffff;
      }
    }
  }
  &__add-button{
    width: 60px;
    height: 60px;
    border: 2px solid #447df7;
    text-align: center;
    border-radius: 50%;
    padding: 5px;
    outline: none !important;
    opacity: 0.8;
    transition: 0.3s ease-in-out;
    background-color: transparent;
    i{
      line-height: 53px;
      transition: 0.3s ease-in-out;
      font-size: 45px;
      font-weight: 600;
      color: #447df7;
    }
    &:hover{
      background-color: #447df7;
      i{
        color: #ffffff;
      }
    }
  }
  .input-area{
    margin-bottom: 10px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  &_with-groups{
    justify-content: flex-start;
    .input-area{
      margin-left: 7px;
      margin-right: 7px;
      &__content{
        padding: 10px 0px!important;
      }
    }
  }
}
.accordion{
  background-color: #fff;
  &__button{
    padding: 22px 70px 22px 19px;
    background-color: #fff;
    border-bottom: 1px solid #E7E7E7;
    transition: 0.3s ease-in-out;
    position: relative;
    user-select: none;
    outline: none!important;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.404255px;
    color: #373737;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:hover{
      background-color: #E7E7E7;
    }
    &[aria-expanded='true']{
      .accordion__icon{
        transform: rotate(180deg);
        background-color: #447df7;
        border-color: #447df7;
        svg {
          & > path {
            fill: #ffffff;
          }
        }
      }
    }
  }
  &__icon{
    max-width: 25px;
    min-width: 25px;
    max-height: 25px;
    min-height: 25px;
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.18);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    transition: 0.3s ease-in-out;
    right: 45px;
    bottom: 0;
    margin: auto 0;
    svg{
      top: 1px;
      position: relative;
      max-width: 12px;
    }
  }
  &__panel{
    animation: fadein 0.35s ease-in;
    background: #FFFFFF;
    padding: 30px;
    border-bottom: 1px solid #E7E7E7;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.DateRangePicker{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  &__CalendarSelection{
    background-color: #447df7;
    border-color: #447df7;
  }
  &__Date{
    &--is-hightlighed{

    }
  }
  &__CalendarHighlight{
    &--single{
      border-color: #447df7;
    }
  }
  &__PaginationArrow{
    position: static;
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    align-self: center;
    max-height: 30px;
    border-radius: 50%;
    &:hover{
      background-color: #447df7;
      .DateRangePicker__PaginationArrowIcon{
        -webkit-filter: invert(100%);
      }
    }
  }
  &__PaginationArrowIcon{
    position: static;
    background-image: url('../../assets/img/arrow.svg');
    background-repeat: no-repeat;
    -webkit-background-size: 20px;
    background-size: 20px;
    background-position: center;
    width: 30px;
    height: 30px;
    border: none;
    margin: 0;
    display: inline-block;
    &--next{
      transform: rotate(-90deg);
    }
    &--previous{
      transform: rotate(90deg);
    }
  }
}
.did-not-work{
  padding: 15px;
  border: 2px solid #447df7;
  border-radius: 15px;
  margin-bottom: 20px;
  &__radios{
    display: flex;
    align-items: center;
    .radio{
      margin: 0;
      padding: 5px;
    }
  }
  &__buttons{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    button{
      &:last-child{
        margin-left: 20px;
      }
    }
  }
  &__radio-title{
    margin-right: 10px;
  }
}
.timelog-grid{
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  background-color: #F1F5F8;
  &__title{
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.40px;
    color: #373737;
  }
  &__row{
    width: 100%;
    background-color: #F1F5F8;
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    &:last-child{
      border-bottom: none;
    }
    .timelog-grid__item:last-child{
      border-right: none;
    }
  }
  &__item{
    max-width: 50%;
    width: 100%;
    padding: 15px 22px;
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &_purple{
      .timelog-grid__icon{
        background-color: #744098;
        img{
          width: 11px;
          margin-left: 3px;
        }
      }
      .timelog-grid__text{
        color: #744098
      }
    }
    &_red{
      .timelog-grid__icon{
        background-color: #FF1E50;
        img{
          width: 10px;
        }
      }
      .timelog-grid__text{
        color: #FF1E50;
      }
    }
    &_black{
      .timelog-grid__icon{
        background-color: #373737;
        img{
          width: 14px;
        }
      }
      .timelog-grid__text{
        color: #373737;
      }
    }
    &_doubled{
      max-width:100%;
      width:100%;
    }
    &:nth-child(2n){
      border-right: none;
    }
  }
  &__icon{
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 11px;
  }
  &__input-text{
    margin-left: auto;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.75px;
  }
  &__text{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.75px;
    padding-right:10px;
    width:100px;
    &-capitalize{
      text-transform: capitalize;
    }
  }
  .big-input{
    text-align: center;
  }
  &__input{
    min-width: 45px;
    margin-left: auto;
    width: auto;
    color: #373737;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.75px;
    &[readonly]{
      pointer-events: none;
      color: #373737;
    }
    &:invalid{
      border-color: #FF1E50;
      color: #FF1E50;
    }
    &:invalid + .timelog-grid__info{
      display: block;
    }
  }
  .time-select{
    margin-left: auto;
    &__control {
      background-color: transparent;
    }
    &__single-value{
      color: #373737;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.75px;
    }
  }
  &__info{
    color: #FF1E50;
    width: 100%;
    display: none;
  }
  input[type="time"]::-webkit-inner-spin-button,
  input[type="time"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="time"]::-webkit-clear-button {
    display: none;
  }
}
.timelog-action-block{
  display: flex;
  align-items: center;
  padding: 15px;
  border: 2px solid #447df7;
  border-radius: 15px;
  margin-bottom: 10px;
  &__label{
    flex-grow: 1;
    padding-right: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.75px;
  }
  &__buttons{
    display: flex;
    align-items: center;
    button{
      margin: 0 5px;
      &:first-child{
        margin-left: 0;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
.flex-card{
  border-radius: 4px;
  background-color: #ffffff;
  margin-bottom: 30px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  overflow: hidden;
  padding: 15px 15px 10px 15px;
  min-height: 151px;
  display: flex;
  flex-direction: column;
  &__header{
    flex-grow: 1;
  }
  &__header-wrap{
    padding-bottom: 15px;
    display: flex;
    align-items: center;
  }
  &__header-icon{
    min-width: 40px;
    i{
      font-size: 40px;
      font-weight: 900;
    }
  }
  &__content{

  }
  &__footer{
    margin-top: auto;
    padding-top: 10px;
    border-top: 1px solid #dddddd;
  }
  &__title{
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    margin: 0;
  }
  &__desc{
    margin-top: 10px;
    display: inline-block;
    color: #a9a9a9;
    font-size: 14px;
    font-weight: 400;
  }
}
.flex-card-footer-buttons{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -5px;
  justify-content: space-around;
  button{
    margin: 5px;
    flex-grow: 1;
    padding: 5px 10px;
  }
};
.react-datepicker{
  border-color: #447df7!important;
  &__triangle{
    border-bottom-color: #ffffff!important;
    &:before{
      border-bottom-color: #447df7!important;
    }
  }
  &__navigation{
    outline: none!important;
    border: 7px solid transparent;
    &--previous{
      border-right-color: #447df7;
    }
    &--next{
      border-left-color: #447df7;
    }
  }
  &__header{
    background-color: #ffffff;
    padding-bottom: 5px;
    border-bottom: 2px solid #447df7;
  }
  &__current-month{
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 7px;
  }
  &__day-names{

  }
  &__day-name{
    font-size: 14px;
    width: 30px;
  }
  &__month{

  }
  &__week{

  }
  &__day{
    width: 30px;
    line-height: 30px;
    font-size: 14px;
    &--selected{
      background-color: #447df7!important;;
    }
  }
  &-wrapper{
    max-width: 300px;
    width: 100%;
  }
  &__input-container{
    width: 100%;
    position: relative;
    &:before{
      content: url("../../assets/img/date.svg");
      display: block;
      width: 21px;
      height: 21px;
      position: absolute;
      left: 21px;
      top: 0;
      margin: auto 0;
      bottom: 0;
      pointer-events: none;
    }

    input{
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #cccccc;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 15px 13px 15px 57px;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.404255px;
      color: #373737;
    }
    input.smaller {
      padding: 8px 13px 11px 50px;
      width: 200px;
    }
  }
}
.register-dist{
  display: flex;
  flex-direction: column;
  &__main-input{
    width: 100%;
    background-color: #F1F5F8;
    display: flex;
    padding: 34px 20px 24px 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    img{
      width: 50px;
      padding-bottom: 14px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.3);
      margin-bottom: 14px;
    }
    &>div{
      display: flex;
      align-items: center;
      span{
        font-weight: 600;
        font-size: 28px;
        line-height: 38px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: #FF1E50;
        width: 100px;
      }
    }
    input{
      font-weight: 600;
      font-size: 28px;
      line-height: 38px;
      height: auto;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      color: #FF1E50;
      width: 100px;
      text-align: right;
      &:focus{
        border: none;
      }
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"]::-webkit-clear-button {
      display: none;
    }
  }
  &__inputs{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  &__input{
    width: 50%;
    box-sizing: border-box;
    &:first-child{
      padding-right: 10px;
    }
    &:last-child{
      padding-left: 10px;
    }
  }
}
.input-block{
  display: block;
  width: 100%;
  padding-top: 10px;
  margin-top: -19px;
  &_no-label{
    margin-top: 0;
  }
  &__label{
    display: inline-block;
    position: relative;
    margin-left: 10px;
    top: 14px;
    max-width: calc(100% - 20px);
    background-color: #ffffff;
    padding: 0 4px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    letter-spacing: 0.45px;
    z-index: 2;
    color: #818181;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__base-input{
    height: 56px;
    border-color: #C4C4C4;
    &::placeholder{
      font-weight: 400;
    }
    &:focus{
      border-color: #447df7;
    }
    &_error{
      border-color: #FF1E50;
      &:focus{
        border-color: #FF1E50;
      }
    }
    &_textarea{
      padding-top: 15px;
      resize: none;
      height: 130px!important;
    }
  }
  &__date-picker{
    line-height: 25px!important;
    &:focus{
      border-color: #447df7!important;
    }
    &_error{
      border-color: #FF1E50!important;;
      &:focus{
        border-color: #FF1E50!important;;
      }
    }
  }
  .react-datepicker-wrapper{
    max-width: 100%;
  }
}
.signature{
  position: relative;
  display: inline-flex;
  background-color: #ffffff;
  margin-top: 10px;
  width: 100%;
  &__label{
    position: absolute;
    top: -10px;
    left: 10px;
    z-index: 5;
    max-width: calc(100% - 20px);
    background-color: #ffffff;
    padding: 0 4px;
    pointer-events: none;
    user-select: none;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    letter-spacing: 0.45px;
    color: #818181;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__bg{
    position: absolute;
    bottom: 20%;
    left: 10%;
    user-select: none;
    right: 10%;
    padding-bottom: 10px;
    border-bottom: 2px solid #D8D8D8;
    pointer-events: none;
    z-index: 1;
  }
  &__panel{
    position: relative;
    z-index: 2;
    border-radius: 4px;
    background-color: transparent;
  }
  &__cancel{
    position: absolute;
    outline: none !important;
    border: none;
    top: 0;
    right: 0;
    z-index: 2;
    background: transparent;
    padding: 0;
    i{
      transition: 0.3s ease-in-out;
    }
    &:hover{
      i{
        color: #447df7;
      }
    }
  }
  &_error{
    * {
      color: #FF1E50;
      border-color: #FF1E50;
    }
  }
}
.m-signature-pad{
  user-select: none;
  height: 100%;
  position: relative;

  &--body{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
  }
  canvas{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    right: 0;
  }
}
.timelog-array{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #ffffff;
  &__left{
    flex-grow: 1;
    width: 66.6%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
  }
  &__left-inputs{
    width: 50%;
    flex-grow: 1;
    padding: 0 10px 10px 10px;
    min-width: 300px;
    box-sizing: border-box;
  }
  &__comment{
    width: 100%;
    padding: 10px;
    box-sizing: border-box
  }
  &__attachment{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px 10px 10px;
    min-width: 300px;
    width: 50%;
    .attachment-box{
      width: 100%;
      height: 100%;
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__right{
    flex-grow: 1;
    min-width: 280px;
    width: 33.3%;
    padding: 10px;
    box-sizing: border-box;
  }
  &__footer{
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    button{
      margin-right: 10px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  &__input{
    margin-bottom: 20px;
  }
  &__row{
    text-align: center;
    padding: 5px;
    display: flex;
    border-bottom: 1px solid #D8D8D8;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.45px;
    min-height: 43px;
    align-items: center;
    &_head{
      background-color: #F2F2F2;
      border-bottom: none;
      font-weight: 600;
    }
    &_not-found{
      justify-content: center;
    }
    &:last-child{
      border-bottom: none;
    }
  }
  &__col{
    &_left{
      text-align: left;
    }
  }
  &__col-action{
    border: none;
    outline: none!important;
    padding: 0;
    transition: .3s ease-in-out;
    &:hover{
      opacity: 0.8;
    }
  }
}
.attachment-box{
  position: relative;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  color: #565656;
  padding: 8px 12px;
  box-shadow: none;
  margin-top: 10px;
  &__label{
    position: absolute;
    top: -10px;
    left: 10px;
    max-width: calc(100% - 20px);
    background-color: #ffffff;
    padding: 0 4px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    letter-spacing: 0.45px;
    color: #818181;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.dynamic-list{
  display: flex;
  flex-wrap: wrap;
  &__top{
    width: 100%;
    text-align: center;
  }
  &__left{
    flex-grow: 1;
    width: 50%;
    min-width: 300px;
    padding: 10px;
  }
  &__right{
    min-width: 300px;
    flex-grow: 1;
    width: 50%;
    padding: 10px;
  }
  &__buttons{
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    button{
      margin-right: 10px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
.timelog-final{
  display: flex;
  flex-direction: column;
  &__item{
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &__item-title{
    font-family: "Open Sans", "Roboto", "Helvetica Neue", sans-serif;
    margin: 0 0 20px 0;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #373737;
  }
  &__item-box{
    font-family: "Open Sans", "Roboto", "Helvetica Neue", sans-serif;
    margin: 0 20px 0 0;
    padding: 15px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: white;
    & img {
      margin-right:5px;
    }
    & b {
      padding-left:50px;
    }
  }
}
.vacation-box{
  display: inline-flex;;
  background: #FFFFFF;
  border-radius: 4px;
  min-width: 350px;
  width: auto;
  &__item{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 12px;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    &:last-child {
      border-right: none;
    }
  }
  &__label{
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.444445px;
    color: #979797;
  }
  &__value{
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.444445px;
    color: #447df7;
  }
}
.vacation-panel{
  display: inline-flex;
  flex-wrap: wrap;
  background: #FFFFFF;
  border-radius: 4px;
  min-width: 350px;
  width: auto;
  &__item{
    display: flex;
    flex-direction: column;
    padding: 12px;
    flex-grow: 1;
    align-items: flex-start;
    min-width: 100px;
  }
  &__label{
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.444445px;
    color: #979797;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      height: 2px;
      width: 67px;
      background: linear-gradient(90deg, #447df7 -1.49%, #FF1E50 50.75%);
    }
  }
  &__value{
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.444445px;
    color: #373737;
  }
}
.vacation-total{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border-bottom: 10px solid #f0f4f7;
  margin-left: -10px;
  margin-right: -10px;

  .vacation-box{
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .vacation-panel{
    flex-grow: 1;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 876px){
  .list-filters{
    border-radius: 14px;
    justify-content: center;
  }
  .vacation-total{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .vacation-box{
      margin-right: 0;
    }
  }
  .vacation-box{
    min-width: 100%;
    width: 100%;
  }
}

@media screen and (min-width: 950px) {
  .modal-dialog {
    width: 1100px;
    margin: 30px auto;
  }
  .modal-dialog.sm {
    width:500px;
    margin:20px auto;
  }
}

.status-block {
  display: inline-block;
  text-align: center;
  padding:3px;
  min-width: 50px;
  font-size:12px;
  margin-right: auto;
  i {
    font-weight: bold;
    font-size:16px;
  }
  border-radius: 5px;
}
.status-actionable {
  border: 2px solid #447df7;
}
.status-active, .status-auto, .status-pending {
  cursor: pointer;
  background-color: darkorange;
  color: white;
  padding-top:10px;
  font-size: 13px;
}
.status-no-click-for-member {
  cursor: default;
}
.status-approved {
  background-color: green;
  color:white;
}
.status-approved-validate-timetrack {
  cursor: pointer;
}
.status-rejected {
  cursor: pointer;
  background-color: red;
  color:white;
}

.dashboard-stats {
  .card-stats {
    .row {
      min-height: 80px;
    }
  }
}
.card-company {
  height: 152px;
  min-height: 152px;
  .company-icon {
    i {
      font-size: 40px;
    }
    img {
      max-height: 80px;
      max-width: 200px;
    }
  }
}
.card{
  &-header{
    &-with-button{
      align-items: center;
    }
  }
  .content{
    padding: 15px 15px 10px 15px;
  }
}
.dashboard-picker-title {
  display: inline-block;
  margin: 15px 20px;
}
.employee-table{
  .rt-tbody {
    .rt-tr-group {
      min-height: auto;
      max-height: 100%;
      margin-top: 10px;
      position: relative;
      .rt-tr {
        height: 100%;
        max-height: 100%;
        .rt-td{
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: -0.6px;
          color: #34525C;
        }
        &.-even{
          background-color: #ffffff!important;
        }
      }
    }
  }
  &__user{
    display: flex;
    align-items: center;
    padding-left: 20px;
    i{
      min-height: 50px;
      min-width: 50px;
      max-height: 50px;
      max-width: 50px;
      background-position: center;
      -webkit-background-size: cover;
      background-size: cover;
      background-color: gray;
      border-radius: 25px;
      margin-right: 10px;
    }
    div{
      max-width: calc(100% - 50px);
      h4{
        margin: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.404255px;
        color: #373737;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
      a{
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.404255px;
        color: #979797;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        width: 100%;
        text-align: left;
        text-overflow: ellipsis;
        &:hover{
          text-decoration: underline;
        }
      }
    }
    &:after{
      content: '';
      height: 100%;
      width: 6px;
      background-color: red;
      top: 0;
      left: 0;
      position: absolute;
    }
    &_work{
      &:after{
        background-color: #5FCD64;
      }
    }
    &_no_work{
      &:after{
        background-color: #FFD037;
      }
    }
    &_absent, &_vacation{
      &:after{
        background-color: #FF1E50;
      }
      &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 30, 80, 0.05);
      }
    }
  }
  &__status{
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.6px;
    color: #5FCD64;
    text-transform: uppercase;
    float: right;

    &_work{
      color: #5FCD64;
    }
    &_no_work{
      color: #FFD037;
    }
    &_absent, &_vacation{
      color: #FF1E50;
    }
  }
  &__start{
    display: inline-flex;
    flex-direction: column;
    min-width: 65%;
    span{
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.6px;
    }
  }
}
.primary-layout{
  background-color: #ffffff;
  &__conetnt{
    min-height: 100vh;
    font-family: "Open Sans", "Roboto", "Helvetica Neue", sans-serif;
  }
  &__conn-err{
    background-color: red;
    padding: 20px;
    text-align: center;
  }
}
.horizontal-scroll_wrap{
  width: 100%;
}
.horizontal-scroll{
  width: 100%;
}
.lp-main{
  padding: 203px 80px 250px 150px;
  background-image: url('../../assets/img/lg/lg_main_bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &_black{
    background-color: #3F3D56;
    a{
      color: #ffffff;
      text-decoration: underline;
      transition: 0.3s ease-in-out;
      &:hover{
        opacity: 0.6;
      }
    }
  }
  &__wrapper{
    position: relative;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
  }
  &__content{
    width: 43%;
    position: relative;
    z-index: 2;
    h1{
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      letter-spacing: 0.444445px;
      color: rgba(255, 255, 255, 0.85);
      margin: 0 0 14px 0;
    }
    p{
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.444445px;
      color: #FFFFFF;
    }
    &-buttons{
      display: flex;
      margin-top: 43px;
      .app-link:first-child{
        margin-right: 27px;
      }
    }
  }
  &__extra-content{
    margin-bottom: 200px;
    color: #ffffff;
  }
  &__img{
    top: -34%;
    left: 40%;
    max-width: 1000px;
    width: 50%;
    position: absolute;
    z-index: 1;
    img{
      width: 100%;
      height: auto;
    }
  }
}
.main-wrapper{
  padding: 30px 15px;
  min-height: calc(100vh - 123px);
}
.lp-section{
  padding-top: 50px;
  padding-left: 144px;
  padding-right: 144px;
  &_contacts{
    width: 100%;
    padding: 0;
    text-align: center;
  }
  &_features{
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  &__title{
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.444445px;
    color: rgba(0, 0, 0, 0.53);
    margin: 0;
    text-transform: uppercase;
  }
  &__sub-title{
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    letter-spacing: 0.444445px;
    color: #343434;
    margin: 0 0 12px 0;
  }
  &__desc{
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    max-width: 600px;
    text-align: center;
    letter-spacing: 0.444445px;
    color: rgba(0, 0, 0, 0.53);
  }
}
.lp-features{
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
  padding-bottom: 30px;
  &__item{
    padding: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-img{
      min-height: 73px;
      display: flex;
      margin-bottom: 10px;
      img{
        margin-top: auto;
        width: 91px;
        height: auto;
      }
    }
    h3{
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.444445px;
      color: #744098;
      margin: 0 0 9px 0;
    }
    span{
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.444445px;
      color: #979797;
    }
  }
}
.lp-ability{
  background-color: rgba(247, 247, 247, 0.63);
  &__wrapper{
    padding: 76px 124px 156px 57px;
    display: flex;
    max-width: 1600px;
    margin: 0 auto;
  }
  &__img{
    img{
      height: auto;
      width: 485px;
    }
  }
  &__list{
    padding: 0 16px;
    .lp-ability-item:last-child{
      margin-bottom: 0;
    }
  }
  &-item{
    display: flex;
    align-items: flex-start;
    margin-bottom: 56px;
    &__img{
      background: linear-gradient(123.99deg, rgba(116, 64, 152, 0.25) 21.24%, rgba(255, 30, 80, 0.25) 90.58%);
      min-width: 82px;
      max-width: 82px;
      min-height: 82px;
      max-height: 82px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
    &__content{
      padding-left: 23px;
      h4{
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.444445px;
        color: #744098;
        margin: 0 0 6px 0;
      }
      p{
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.444445px;
        color: #979797;
        margin: 0;
      }
    }
  }
}
.lp-app-for{
  padding: 64px 156px;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  &__item{
    padding: 12px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-height: 255px;
    //max-height: 255px;
    img{
      width: 100%;
      height: auto;
    }
    &_25{
      min-width: 25%;
      max-width: 25%;
    }
    &_50{
      min-width: 50%;
      max-width: 50%;
    }
    &_75{
      min-width: 75%;
      max-width: 75%;
    }
    &_100{
      min-width: 100%;
      max-width: 100%;
    }
  }
  &-main{
    padding: 35px 62px;
    /*min-height: 280px;*/
    background: linear-gradient(90.68deg, #744098 0.29%, #600461 99.83%);
    h4{
      font-size: 26px;
      line-height: 31px;
      letter-spacing: 0.444445px;
      color: #FFFFFF;
      margin: 0 0 52px 0;
    }
    &__content{
      span{
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.444445px;
        color: rgba(255, 255, 255, 0.57);
      }
      img{
        float: right;
        width: 29%;
        margin-top: -8%;
        min-width: 100px;
      }
    }
  }
}
.lp-contacts{
  display: flex;
  width: 100%;
  margin-top: 45px;
  padding-bottom: 43px;
  &__img{
    width: 50%;
    background-color: #F2F2F2;
    padding: 81px 24px 53px;
    display: flex;
    img{
      margin-left: auto;
    }
  }
  &__form{
    width: 50%;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &-input {
      width: 100%;
      margin-bottom: 7px;
      &_s{
        width: calc(50% - 6px);
      }
    }
    &-footer {
      margin-top: 10px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      div:first-child{
        margin-right: 10px;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.7px;
        color: #979797;
      }
    }
  }
}
.lp-footer{
  width: 100%;
  background-color: #3F3D56;
  padding-top: 51px;
  display: flex;
  flex-direction: column;
  h3{
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.444445px;
    color: #FFFFFF;
    margin: 0 0 34px 0;
  }
  &__main{
    margin: 0 auto;
    padding: 0 20px 68px 20px;
    display: inline-flex;
    flex-direction: column;
    &_row{
      display: flex;
      justify-content: space-between;
      margin-bottom: 34px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    a{
      font-size: 18px;
      line-height: 121%;
      letter-spacing: 0.444445px;
      color: rgba(255, 255, 255, 0.6);
      transition: 0.3s ease-in-out;
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      img{
        max-width: 20px;
        margin-right: 10px;
        transition: 0.3s ease-in-out;
        opacity: 0.46;
      }
      &:hover{
        color: #ffffff;
        img{
          opacity: 1;
        }
      }
    }
  }
  &__bottom{
    border-top: 1px solid rgba(255, 255, 255, 0.17);
    &-wrapper{
      max-width: 1400px;
      margin: 0 auto;
      padding: 20px 50px;
      align-content: center;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
    }
    &-logo{
      img{

      }
    }
    &-download{
      color:rgba(255, 255, 255, 0.6);
      font-size: 16px;
      line-height: 17px;
      padding-top:12px;
      text-decoration: underline;
      &:hover {
        color:white;
        text-decoration: underline;
      }
    }
    &-texts{
      display: flex;
      align-items: center;
      span{
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.444445px;
        color: rgba(255, 255, 255, 0.6);
        border-right: 1px solid rgba(255, 255, 255, 0.17);
        padding: 0 20px;
        &:last-child{
          border-right: none;
        }
      }
    }
    &-social{
      a{
        margin-right: 25px;
        opacity: 0.46;
        transition: 0.3s ease-in-out;
        &:last-child{
          margin-right: 0;
        }
        img{
          max-width: 40px;
        }
        &:hover{
          opacity: 1;
        }
      }
    }
  }
}
.gradient_button{
  outline: none!important;
  border: none!important;
  background: linear-gradient(90deg, #744098 1.39%, #FF1E50 60.14%, #744098 98.61%);
  border-radius: 4px;
  padding: 13px 29px;
  line-height: 19px;
  letter-spacing: 0.718136px;
  color: #FFFFFF;
}
.app-link{
  border: none!important;
  outline: none!important;
  background-color: transparent;
  max-width: 157px;
  img{
    width: 100%;
  }
}

.login-page, .register-page, .forgot-page{
  padding-top: 120px;
  min-height: 100vh;
  background: #754199;
  .header-text{
    padding-top: 0;
  }
  .card{
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.lp-primary-button{
  font-weight: 600;
  font-size: 16px;
  line-height: 16px!important;
  letter-spacing: 0.45px;
  color: #FFFFFF;
  padding: 10px 24px;
  border: 1px solid #FFFFFF!important;
  box-sizing: border-box;
  border-radius: 4px;
  opacity: 1!important;
  i{
    font-size: 12px;
    margin-right: 10px;
  }
  &:hover{
    opacity: 0.8!important;
  }
}
.lp-secondary-button{
  font-weight: 600;
  font-size: 16px;
  line-height: 16px!important;
  letter-spacing: 0.45px;
  color: #FFFFFF;
  padding: 10px 24px;
  border: 1px solid #744098!important;
  background-color: #744098!important;
  box-sizing: border-box;
  border-radius: 4px;
  opacity: 1!important;
  transition: 0.3s ease-in-out;
  i{
    font-size: 12px;
    margin-right: 10px;
  }
  &:hover{
    opacity: 0.8!important;
  }
}
.lp-header{
  .navbar-nav {
    &> li > a{
      margin: 10px!important;
    }
  }
  .lp-text-btn{
    padding: 10px 5px;
    float: left;
  }
  #basic-nav-dropdown-1{
    padding: 0;
    margin: 0!important;
    opacity: 1;
  }
}
.nav-open .lp-header.navbar .container, .nav-open .main-panel, .nav-open .wrapper-full-page{
  transform: translate3d(-100%, 0, 0);
}
.nav-open .lp-header{
  background-color: #447df7;
}
.lp-text-btn{
  outline: none!important;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  letter-spacing: 0.444445px;
  color: #FFFFFF;
  margin: 10px;
  i{
    margin-right: 5px;
    font-size: 14px;
  }
}
.sidebar-user.user{
  width: 100%;
  a{
    display: flex;
    align-items: stretch;
    box-sizing: border-box;
    img.photo{
      margin-left: 0;
      min-width: 34px;
      min-height: 34px;
    }
    p{
      flex-grow: 1;
      line-height: 34px;
      opacity: 1;
      display: block;
      position: relative;
    }
  }
}
@media screen and (max-width: 998px){
  .lp-header{
    .nav-link.navbar-brand{
      display: inline-flex;
      padding: 0 20px;
    }
    .navbar-nav > li > a{
      margin: 0 5px!important;
    }
    .nav-link{
      &.lp-primary-button, &.lp-secondary-button{
        margin: 5px!important;
      }
    }
    .dropdown{
      .lp-text-btn{
        width: 100%;
        text-align: center;
      }
    }
    .lp-text-btn{
      margin: 0 5px;
    }
    .dropdown-menu:after, .dropdown-menu:before{
      display: none!important;
    }
    .dropdown-menu li{
      display: inline-flex;
      justify-content: center;
      width: 100%;
      a{
        padding: 10px 5px!important;
      }
    }
    .navbar-nav {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    // .navbar-collapse{
    //   margin-top: 10px;
    // }
    // .navbar-collapse{
    //   border-bottom: 1px solid #ffffff;
    //   border-top: 1px solid #ffffff;
    // }
    .navbar-collapse{
      display: none !important;
    }
  }
  .lp-main{
    padding: 100px 20px;
    &__content{
      width: 100%;
      position: relative;
      z-index: 2;
      &-buttons{
        justify-content: center;
      }
    }
    &__img{
      display: none;
    }
  }
  .lp-section{
    padding-left: 50px;
    padding-right: 50px;
  }
  .lp-features{
    &__item{
      padding: 20px;
      width: 50%;
    }
  }
  .lp-ability{
    &__wrapper{
      padding: 50px;
    }
    &__img{
      display: none;
    }
  }
  .lp-app-for{
    padding: 50px 30px;
    &__item{
      &_s_100{
        min-width: 100%;
        max-width: 100%;
      }
      &_s_75{
        min-width: 75%;
        max-width: 75%;
      }
      &_s_50{
        min-width: 50%;
        max-width: 50%;
      }
    }
    &-main{
      padding: 30px;
      h4{
        margin: 0 0 20px 0;
      }
      &__content{
        img{
          display: none;
        }
      }
    }
  }
  .lp-contacts{
    margin-top: 25px;
    padding-bottom: 43px;
    &__img{
      display: none;
    }
    &__form{
      width: 100%;
    }
  }
  .lp-footer{
    &__bottom {
      &-wrapper {
        padding: 20px 30px;
        flex-direction: column;
        align-items: center;
      }

      &-texts {
        flex-direction: column;

        span {
          margin-top: 20px;
          border-right: none;
        }
      }
      &-social {
        margin-top: 20px;
      }
    }
  }
}
@media screen and (max-width: 600px){
  .lp-section{
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    &__sub-title{
      font-size: 30px;
      line-height: 40px;
    }
  }
  .lp-features{
    &__item{
      padding: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .lp-ability{
    &__wrapper{
      padding: 30px 20px;
    }
    &-item{
      margin-bottom: 30px;
      flex-direction: column;
      align-items: center;
      &__img{
        margin-bottom: 15px;
      }
      &__content{
        padding-left: 0;
        width: 100%;
        h4{
          text-align: center;
        }
        p{
          text-align: center;
        }
      }
    }
  }
  .lp-app-for{
    padding: 20px 10px;
    &__item{
      padding: 6px;
    }
    &-main{
      padding: 20px 15px;
      min-height: auto;
      h4{
        font-size: 20px;
        line-height: 25px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      &__content{
        span{
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
  .lp-contacts{
    &__form{
      &-input {
        &_s{
          width: 100%;
        }
      }
    }
  }
  .lp-footer{
    h3{
      margin: 0 0 15px 0;
    }
    &__main{
      padding: 0 20px 10px 20px;
      &_row{
        flex-direction: column;
        margin-bottom: 0;
      }
      a{
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
    &__bottom{
      &-texts{
        span{
          text-align: center;
        }
      }
    }
  }
}

.leaflet-container {
  height:100%;
  width: 100%;
}

.dropdown-menu-right .dropdown-menu:before {
  left:inherit;
  right:12px;
}
.dropdown-menu-right .dropdown-menu:after {
  left:inherit;
  right:12px;
}
.gps-info-form{
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &__title{
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.40px;
    color: #373737;
  }
  &__item{
    display: inline-flex;
    flex-basis: 1px;
    flex-grow: 1;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.09);
    background-color: #F1F5F8;
    border-left: 0;
    &:last-child{
      border-right: 0;
    }
    &-label{
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.75px;
      padding-right: 10px;
      flex-grow: 1;
    }
    a.gps-info-form__item-value{
      cursor: pointer;
    }
    &-value{
      display: inline-flex;
      align-items: center;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.75px;
      i{
        margin-right: 5px;
        font-weight: 900;
        font-size: 26px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .gps-info-form{
    &__item{
      border: 1px solid rgba(0, 0, 0, 0.09)!important;
    }
  }
}
.gps-info-on-list {
  cursor: pointer;
  border-radius: 3px;
  padding:2px;
  &:hover {
    background: white;
  }
}

.notification-button-active {
  color: #fb404b !important;
}
.chat-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4c83f7;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 15px;
  color: white;  
  float:right;  
  padding: 2px;
}
// Confirm Alert Customization
.react-confirm-alert-body_custom{
  width: auto;
  max-width: 600px;
}
.custom-confirm-box{
  max-width: 800px;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 15px 10px 15px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  &__title{
    width: 100%;
    display: inline-block;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0.45px;
    color: #818181;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 5px;
  }
  &__desc{
    font-size: 16px;
    color: #818181;
    padding: 20px 5px;
    display: inline-block;
  }
  &__buttons{
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0;
  }
  &__button{
    padding: 5px;
  }
}
.react-datepicker-popper{
  z-index: 2;
}
.font-weight-bold {
  .checkbox label, .radio label {
    font-weight: bold;
  }
}
.checkbox.checkbox-inline {
  margin-top:0;
}

.project-members-form {

}

.managers-widget{
  .react-select__control {
    .react-select__value-container {
      flex-wrap: nowrap !important;
    }
    .react-select__multi-value {
      min-width: 70px;
    }
  }
}
// New css by bhoomika
.react-confirm-alert-overlay{
  z-index: 9999;
}

@import './responsive.scss';


