.conversation-search {
  margin: 10px 10px 10px 0;
  display: flex;  
  /* flex-direction: column;   */
  justify-content: space-between;
  border: 1px solid #dbe5ed;
  background: #f9fafc;
  border-radius: 5px;  
  padding: 8px 10px;  
}

.conversation-search-input {
  border: none;    
  width: 90%;
  background: #f9fafc;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;  
}

.conversation-search-input::placeholder{
  color: #afbbc6;
}
.conversation-search-icon{
  color: #afbbc6;
  padding:5px ;
  cursor: pointer;
}
