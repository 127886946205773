.progress-bar{
    background-color: #33c85b!important;
    cursor: pointer;
}
.draft{
    background-color: #C0C0C0;
    cursor: pointer;
}

.progress-bar-primary{
    background-color: #3b78fc !important;
    cursor: pointer;
}
.d-none{
    display: none !important;
}
.pending{
    background-color: orange;
    cursor: pointer;
}

.expired_color{
    background-color: red;
    color : white;
}




