
.react-time-picker__wrapper{
    border-radius: 5px;
    padding: 1rem 0 1rem 1rem;
    border: thin solid #cccccc;
}

.worklog-input{
    background: #fff;
    color: #565656;    
    font-weight: 600;
}