.toolbar {
  display: flex;
  align-items: center;  
  font-weight: 500;  
  padding: 10px;
  position: sticky;
  top: 0px;
  border: 1px solid #dbe5ed;
  border-radius: 5px;
  background-color:#f4f5f8;
}

@supports (backdrop-filter: blur(20px)) {
  .toolbar {    
    backdrop-filter: blur(20px);
  }
}

.toolbar-title {
  margin: 10px 5px;
  font-size: 16px;
  font-weight: 800;
  color: #4b5155;
}

.left-items, .right-items {
  flex: 1;
  padding: 10px;
  display: flex;
}

.right-items {
  flex-direction: row-reverse;
}

.left-items .toolbar-button {
  margin-right: 20px;
}

.right-items .toolbar-button {
  margin-left: 20px;
}

.left-items .toolbar-button:last-child,
.right-items .toolbar-button:last-child {
  margin: 0;
}