.conversation-list {
  display: flex;
  flex-direction: column;
}

.conversation-tab .conversation-list-title-project{
  /* background-color: rgb(206, 206, 225) ; */
  cursor: pointer;
  color: #4b5155;
  padding: 11px;
    text-align: center;
    margin:10px;
    border-radius: 10px;
    width: 50%;
    font-weight: bolder;
}
.conversation-list-title-project-active{
  background-color: rgb(205, 205, 224);
  cursor: pointer;
  padding: 11px;
    text-align: center;
    margin:10px;
    border-radius: 10px;
    width: 50%;
    font-weight: bolder;
}

.conversation-tab .conversation-list-title-user{
  /* background-color: rgb(205, 205, 224) ; */
  cursor: pointer;
  padding: 11px;
    text-align: center;
    margin:10px;
    color: #4b5155;
    border-radius: 10px;
    width: 50%;
    font-weight: bolder;
}
.conversation-list-title-user-active{
  background-color: rgb(205, 205, 224);
  cursor: pointer;
  padding: 11px;
    text-align: center;
    margin:10px;
    border-radius: 10px;
    width: 50%;
    font-weight: bolder;
}
.conversation-tab{
  display: flex;
  justify-content: space-between;
  background-color: #f4f5f8;
  border-radius: 7px;
  margin-right:10px;
}
.conversation-tab p{
  margin : 0 !important;
}
.users-chat{
  margin: 10px 10px 10px 0;
  display: flex;  
  /* flex-direction: column;   */
  justify-content: space-between;
  border: 1px solid #dbe5ed;
  background: #f9fafc !important;
  border-radius: 5px;  
  width:287px;

}
.users-chat .dropdown-heading{
background-color: #f9fafc !important;
border: none !important;
width: 90%;
    background: #f9fafc;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    
}
.users-chat .dropdown-heading::placeholder{
  color: #afbbc6;
}
.users-chat .dropdown-heading span{
padding-top: 2px !important;
}
.users-chat .managers-widget{
  min-height: 0 !important;
}
.buttons-chat{
   display: flex;
  justify-content: right;
  /* margin-right: 10px;
  margin-top: 25px;  */
}
.createroombutton{
  margin-right: -50px;
  cursor: pointer;
}
.createroombutton:disabled{
  cursor: not-allowed;
}
.flexconversationdata{
  display: flex;
}